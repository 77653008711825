<template>
    <div class="aboutUs-container">
        <header-nav></header-nav>

        <div class="main">
            <section ref="section-1" class="section headeImg">
                <div class="contentBox">
                    <p class="h1p">智慧校园后勤整体解决方案</p>
                    <p class="h5p">天诚智慧校园解决方案保证管理制度落地的同时，降低校园师生的学习成本，降低建设落地的实施成本。打破 “信息孤岛” ，与校园内各方子系统有机融合。</p>
                </div>
            </section>

            <section ref="section-2" class="section businessPainPoint">
                <div class="contentBox">
                    <div class="solutionName">业务痛点</div>
                    <div class="list">
                        <div class="item">
                            <div class="titieWarp">
                                <img class="icon" src="../assets/images/school/listItem1.png" alt="">
                                <p>功能室管理混乱</p>
                            </div>
                            <div class="contont">
                                例如：机房、琴房、剧院、会议室、实验室、实训中心等已经有师生在使用了，信息不对称，时间冲突无法避免。
                            </div>
                        </div>
                        <div class="item">
                            <div class="titieWarp">
                                <img class="icon" src="../assets/images/school/listItem2.png" alt="">
                                <p>房态难核实</p>
                            </div>
                            <div class="contont">
                                房间太多，人工登记工作繁杂低效，费时费力还容易出错，造成很多的房间空置。
                            </div>
                        </div>
                        <div class="item">
                            <div class="titieWarp">
                                <img class="icon" src="../assets/images/school/listItem3.png" alt="">
                                <p>安全风险高</p>
                            </div>
                            <div class="contont">
                                学生经常忘带、遗失钥匙，传统钥匙随便乱配现象严重，寝室安全得不到保证，容易造成偷盗的风险。
                            </div>
                        </div>
                        <div class="item">
                            <div class="titieWarp">
                                <img class="icon" src="../assets/images/school/listItem4.png" alt="">
                                <p>实名开锁难</p>
                            </div>
                            <div class="contont">
                                传统钥匙谁开门不能实名查询，如果遇到意外的情况发生，无法溯源。
                            </div>
                        </div>
                        <div class="item">
                            <div class="titieWarp">
                                <img class="icon" src="../assets/images/school/listItem5.png" alt="">
                                <p>故障排查麻烦</p>
                            </div>
                            <div class="contont">
                                一旦门锁出现故障，需要学生上报，宿管再联系人来进行维修，时间很长，不能及时解决。
                            </div>
                        </div>
                        <div class="item">
                            <div class="titieWarp">
                                <img class="icon" src="../assets/images/school/listItem6.png" alt="">
                                <p>钥匙管理难</p>
                            </div>
                            <div class="contont">
                                传统门锁机械钥匙容易丢失，且复制简单，增加房间被偷盗的风险。宿舍、教室、实验室、办公楼等场所如有东西丢失。
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section ref="section-3" class="section solution">
                <div class="sections">
                    <div class="contentBox"> 
                        <div class="solutionName" style="color: #FAFBFC;">智慧校园后勤整体解决方案</div>
                        <div class="list">
                            <div class="item">
                                <div class="titieWarp">
                                    <p>房态管理</p>
                                </div>
                                <div class="contont">
                                    以建筑楼栋和院系专业双维度管理查看人员入住情况、历史住宿信息
                                </div>
                            </div>
                            <div class="item">
                                <div class="titieWarp">
                                    <p>人员管理</p>
                                </div>
                                <div class="contont">
                                    管理组织部门信息、师生信息，指纹、人脸可在对应模块进行录入
                                </div>
                            </div>
                            <div class="item">
                                <div class="titieWarp">
                                    <p>入住管理</p>
                                </div>
                                <div class="contont">
                                    对所有学生与职工从入住、换寝、退宿整个宿舍管理进行统一管理
                                </div>
                            </div>
                            <div class="item">
                                <div class="titieWarp">
                                    <p>事件中心</p>
                                </div>
                                <div class="contont">
                                    开门事件、连错、防拆、晚归、未归等事件告警
                                </div>
                            </div>
                            <div class="item">
                                <div class="titieWarp">
                                    <p>一码通行</p>
                                </div>
                                <div class="contont">
                                    实现远程授权、远程开锁、远程查询等功能，使门禁管理更加智能化
                                </div>
                            </div>
                            <div class="item">
                                <div class="titieWarp">
                                    <p>智能设备</p>
                                </div>
                                <div class="contont">
                                    集中管理智能门锁，智能水电表、门禁闸机、智能监控、班牌设备等
                                </div>
                            </div>
                            <div class="item">
                                <div class="titieWarp">
                                    <p>访客管理</p>
                                </div>
                                <div class="contont">
                                    将参观人员的人脸直接下发到门禁闸机上，方便其可以直接进入
                                </div>
                            </div>
                            <div class="item">
                                <div class="titieWarp">
                                    <p>考勤管理</p>
                                </div>
                                <div class="contont">
                                    灵活配置排班规则，选择不同区域设备，让考勤人员在设备进行打卡
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section>

            <section ref="section-4" class="section framework">
                <div class="contentBox">
                    <div class="solutionName">产品模块架构</div>
                    <img class="infoImg" src="../assets/images/school/framework.png" alt="">
                </div>
            </section>

            <section ref="section-5" class="section flow">
                <div class="contentBox">
                    <div class="solutionName">校园后勤管理——人脸无感通行</div>
                    <img class="infoImg" src="../assets/images/school/flow.png" alt="">
                </div>
            </section>

            <section ref="section-6" class="section advantage">
                <div class="contentBox">
                    <div class="solutionName">系统功能优势</div>
                    <div class="title">以物联网为核心的智慧化的校园学习、生活一体化的环境，通过信息化手段将教学、教务管理和校园生活进行充分融合，实现智慧化服务和管理的校园模式。</div>
                    <div class="infoTable">
                        <img class="advantageTableImg" src="../assets/images/school/tableImg.jpg" alt="">
                        <div class="tableWarp">
                            <div class="item">
                                <img class="icon" src="../assets/images/school/table1.png" alt="">
                                <p>高效对接第三方平台</p>
                            </div>
                            <div class="item">
                                <img class="icon" src="../assets/images/school/table2.png" alt="">
                                <p>校园设备智能维护</p>
                            </div>
                            <div class="item">
                                <img class="icon" src="../assets/images/school/table3.png" alt="">
                                <p>师生统一权限管理</p>
                            </div>
                            <div class="item">
                                <img class="icon" src="../assets/images/school/table4.png" alt="">
                                <p>数字化管理平台</p>
                            </div>
                            <div class="item">
                                <img class="icon" src="../assets/images/school/table5.png" alt="">
                                <p>数据实时查看掌控</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <footer-nav></footer-nav>
        <!-- 定位栏 -->
        <location-column></location-column>
    </div>
</template>
  
<script>
  import headerNav from "@/components/header-nav";
  import footerNav from "@/components/footer-nav";
  export default {
    name: "cooperation",
    components: { headerNav, footerNav },
    metaInfo: {
      meta: [
        {
          name: "keywords",
          content:
            "新巢天诚NB-IoT智能门锁，CAT.1物联网锁，Wi-Fi智能锁，4G智能门锁，校园智能门锁，保障性住房管理平台，公租房管理系统，人才公寓管理系统，网约房（出租屋管理系统），智慧校园后勤管理系统，智慧园区企业后勤管理系统。",
        },
        {
          name: "description",
          content:
            "江苏新巢天诚智能技术有限公司（简称：天诚）专注于物联网多形态智能硬件的产品研发与制造，为不同垂直领域的客户提供整体解决方案服务，深度赋能公租房.人才公寓、智慧公寓、人才公寓、保障性住房、智慧校园、企业园区宿舍、智慧后勤，网约房（出租屋）等行业应用场景。天诚自主研发的多模态计算机视觉与生物识别物联网锁，采用NB-IoT、CAT.1、Wi-Fi、4G,5G等无线通讯技术。打造整体的智能硬件解决方案。",
        },
      ],
    },
    data() {
        return {
            copyDom: "",
            lastScrollTop: 0,
            scrollDirection: 'down',
            isScrolling: false,
            scrollStopTimer: null,
            scrollThrottle: null,
        };
    },
    methods: {
        getAnimationClass(section) {
            return Array.from(section.classList).find(item => item.startsWith('animationClass-'));
        },

        handleScroll() {
            this.isScrolling = true;
            clearTimeout(this.scrollStopTimer)
            this.scrollStopTimer = setTimeout(() => {
                this.isScrolling = false
            }, 150)

            if (!this.scrollThrottle) {
                this.scrollThrottle = requestAnimationFrame(() => {
                // 获取窗口滚动位置（兼容不同浏览器）
                const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

                // 保持原有方向判断逻辑
                if (Math.abs(currentScrollTop - this.lastScrollTop) > 10) {
                    this.scrollDirection = currentScrollTop > this.lastScrollTop ? 'down' : 'up';
                }
                    this.lastScrollTop = currentScrollTop;
                    this.scrollThrottle = null;
                })
            }
        },

        // 动画触发方法
        triggerAnimation(section) {
            section.classList.remove('active');   // 移除动画类以确保可以重新触发
            void section.offsetWidth; // 强制重绘，确保动画重置
            section.classList.add('active'); // 重新添加动画类以触发淡入


            // //类名不固定时使用
            // const animationClass = this.getAnimationClass(section); //获取class类名
            // section.classList.remove(`${animationClass}-active`);   // 移除动画类以确保可以重新触发
            // void section.offsetWidth; // 强制重绘，确保动画重置
            // section.classList.add(`${animationClass}-active`); // 重新添加动画类以触发淡入
            
            // // 动画结束后自动移除类
            // section.addEventListener('animationend', () => {
            //     section.classList.remove(`${animationClass}-active`);
            // }, { once: true });
        },
    },
    created() {
        
    },
    mounted() {
        document.title = this.$store.state.searchTitle;

        window.addEventListener('scroll', this.handleScroll);
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if(this.scrollDirection == 'down'){ 
                        if(this.copyDom == entry.target) return;
                        console.log("当前过渡元素",entry.target)

                        const section = entry.target;
                        this.triggerAnimation(section);
                        this.copyDom = entry.target;
                    }else{ 
                        console.log("向上滚动")
                        this.copyDom = '';
                    }
                }
            })
        },{
            threshold: 0,
            // rootMargin: "0px 0px -100px 0px" // 添加底部负边距防止过早触发
        });

        this.$nextTick(() => {
            for(let i = 1; i <= 6; i++) {
                observer.observe(this.$refs[`section-${i}`])
            }
        });
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
    },
  };
</script>
  
<style scoped lang="less">
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(150px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.aboutUs-container {
    /* min-width: 1366px; */
    .main {
        width: 100%;
        padding-top: 80px;
        .section{
            border: 1px solid transparent;
            .contentBox{
                width: 1300px;
                margin: 0 auto;
                opacity: 0;
                transition: opacity 0.5s ease-out;
                .solutionName{
                    height: 28px;
                    line-height: 28px;
                    font-size: 31px;
                    color: #000103;
                    position: relative;
                    margin-top: 50px;
                    // padding-bottom: 25px;
                    // margin-bottom: 12px;
                    &::after{
                        position: absolute;
                        content: "";
                        height: 3px;
                        width: 156px;
                        background-color: #1B5CDB;
                        bottom: -30px;
                        left: calc(50% - 78px); 
                    }
                }
            }
            &.active{
                .contentBox{
                    animation: fadeIn 1.5s forwards;
                }
            }
        }
        .headeImg{
            width: 100%;
            height: 749px;
            // height: 562px;
            background-image: url("../assets/images/school/backImg.jpg");
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            // &.active{
            //     .contentBox{
            //         animation: fadeIn 1s forwards;
            //     }
            // }
            .contentBox{
                height: 100%;
                padding-top:179px;
                .h1p{
                    height: 42px;
                    line-height: 42px;
                    font-size: 46px;
                    color: #F3F6FB;
                    text-align: left;
                    opacity: 0.9;
                }
                .h5p{
                    margin-top: 45px;
                    max-width: 712px;
                    height: 59px;
                    line-height: 45px;
                    font-size: 19px;
                    color: #F9FAFB;
                    text-align: left;
                    opacity: 0.8;
                }
            }
        }
        .businessPainPoint{
            .contentBox{
                width: 1300px;
                // min-height: 785px;
                .list{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: 70px;
                    margin-bottom:40px;
                    .item{
                        // width: 46%;
                        // height: 177px;
                        width: 630px;
                        min-height: 153px;
                        padding: 17px 31px 0px 31px;
                        box-sizing: border-box;
                        background: #BEDFE9AD;
                        border-radius: 18px 18px 18px 18px;
                        margin-bottom: 40px;
                        .titieWarp{
                            display: flex;
                            align-items: flex-end;
                            .icon{
                                width:42px;
                                height:42px;
                                margin-right: 10px;
                            }
                            p{
                                font-size: 26px;
                                color: #000103;
                            }
                        }
                        .contont{
                            text-align:left;
                            margin-top:10px;
                            margin-left: 46px;
                            font-size: 18px;
                            color: #606060;
                            line-height:26px;
                        }
                    }
                }
            }
        }
        .sections{
            background-color: #000103E8;
        }
        .solution{
            background-image: url("../assets/images/school/system.png");
            // background-size: cover;
            background-size: 100%;
            background-position: top left;
            background-repeat: no-repeat;
            .contentBox{
                width: 1321px;
                min-height: 700px;
                border: 1px solid transparent;
                .list{
                    margin-top: 112px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .item{
                        width: 306px;
                        min-height: 192px;
                        background: #08126DAB;
                        padding: 40px 24px 16px;
                        box-sizing: border-box;
                        margin-bottom: 30px;
                        .titieWarp{
                            display: flex;
                            align-items: center;
                            p{
                                font-size: 25px;
                                color: #FAFBFC;
                                height: 23px;
                                line-height: 23px;
                                position: relative;
                                &::after{
                                    position: absolute;
                                    content: "";
                                    height: 3px;
                                    width: 60px;
                                    background-color: #A6CCFC;
                                    border-left: 30px solid #438EF9;
                                    bottom:-18px;
                                    left: 0px; 
                                }
                            }
                        }
                        .contont{
                            margin-top: 23px;
                            text-align:left;
                            font-size: 16px;
                            color: #A5ACB8;
                            line-height:38px;
                        }
                    }
                }
            }
        }
        .framework{
            background-color: #D2EBFC4D;
            .contentBox{
                width:1300px;
                // min-height: 856px;
                .infoImg{
                    width:1300px;
                    // height: 642px;
                    margin-top:80px;
                    margin-bottom:80px;
                }
            }
        }
        .flow{
            .contentBox{
                width:1300px;
                // min-height: 860px;
                .infoImg{
                    width:1300px;
                    // height: 746px;
                    margin-top:16px;
                    margin-bottom:80px;
                }
            }
        }
        .advantage{
            background-color: #BEDFE9;
            .contentBox{
                width:1300px;
                // min-height: 1020px;
                .title{
                    width: 1300px;
                    line-height:51px;
                    font-size: 28px;
                    color: #000103;
                    text-align: left;
                    margin: 61px 0px;
                }
                .infoTable{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 76px;
                    margin-bottom:100px;
                    .advantageTableImg{
                        width: 756px;
                        // height: 530px;
                    }
                    .tableWarp{
                        width: 460px;
                        // min-height: 392px;
                        // min-height: 559px;
                        background: #F5F7FB;
                        border-radius: 24px 24px 24px 24px;
                        padding: 0px 40px;
                        box-sizing: border-box;
                        // border: 4px solid #DAE5F8;
                        .item{
                            height: 78px;
                            border-bottom: 2px dashed #C3C3C7;
                            display: flex;
                            align-items: center;
                            &:last-child{
                                border-bottom:0px;
                            }
                            .icon{
                                width: 60px;
                                height:60px;
                                margin-right: 22px;
                            }
                            p{ 
                                font-size: 19px;
                                color: #000104;
                            }
                        }
                    }
                }
            }
        }
    }
}
//   .header-content {
//     height: 160px;
//     margin: auto;
//     padding-top: 120px;
//     padding-left: 20px;
//   }
//   .title {
//     font-size: 40px;
//     margin-top: 30px;
//     font-weight: bold;
//   }
//   .contact_list {
//     margin-top: 40px;
//     width: 1200px;
//     margin: auto;
//   }
//   .line {
//     width: 80px;
//     margin: auto;
//     border-bottom: 2px solid #d34100;
//     margin-top: 10px;
//   }
//   .content {
//     font-size: 15px;
//     padding-top: 20px;
//     color: #333333;
//     margin: auto;
//     text-align: left;
//     letter-spacing: 2px;
//     line-height: 25px;
//   }

@media screen and (max-width: 768px) {
    .main {
      padding-top: 50px !important;
    }
    .main-header {
      height: 166px !important;
    }
    .solutionName {
      font-size: 20px !important;
      margin-top: 20px !important;
      &::after{
        height: 3px !important;
        width: 83px !important;
        bottom: -10px !important;
        left: calc(50% - 41px) !important; 
      }
    }
    .headeImg{
        height: 166px !important;
        .contentBox{
            padding-top:25px !important;
            .h1p{
                height: 42px !important;
                line-height: 42px !important;
                font-size: 24px !important;
                padding-left: 10px;
            }
            .h5p{
                margin-top: 5px !important;
                max-width: 712px !important;
                height: 59px !important;
                line-height: 22px !important;
                font-size: 14px !important;
                padding-left: 10px;
            }
        }
    }
    .contentBox{
        width: 100% !important;
    }

    .businessPainPoint{
        .contentBox{
            width: 100% !important;
            .list{
                margin-top: 40px !important;
                margin-bottom:10px !important;
                .item{
                    width: 100% !important;
                    min-height: 123px !important;
                    padding: 10px 16px 0px 20px !important;
                    margin: 0px 30px !important;
                    margin-bottom: 20px !important;
                    .titieWarp{
                        .icon{
                            width:30px !important;
                            height:30px !important;
                            margin-right: 10px !important;
                        }
                        p{
                            font-size: 16px !important;
                        }
                    }
                    .contont{
                        margin-top:6px !important;
                        margin-left: 30px !important;
                        font-size: 14px !important;
                        line-height:20px !important;
                    }
                }
            }
        }
    }
    .solution{
        .contentBox{
            width: 100% !important;
            min-height: 700px !important;
            .list{
                margin-top: 40px !important;
                .item{
                    width: 100% !important;
                    min-height: 123px !important;
                    padding: 20px 18px 6px !important;
                    margin-bottom: 30px !important;
                    margin: 0px 30px 30px !important;
                    .titieWarp{
                        p{
                            font-size: 16px !important;
                            height: 23px !important;
                            line-height: 23px !important;
                            &::after{
                                height: 3px !important;
                                width: 40px !important;
                                bottom:-6px !important;
                                border-left: 20px solid #438EF9 !important;
                                left: 0px !important;
                            }
                        }
                    }
                    .contont{
                        margin-top: 15px !important;
                        font-size: 14px !important;
                        line-height:25px !important;
                    }
                }
            }
        }
    }
    .framework{
        .contentBox{
            width: 100% !important;
            .infoImg{
                width: 100% !important;
                margin-top: 40px !important;
                margin-bottom: 34px !important;
            }
        }
    }
    .flow{
        .contentBox{
            width: 100% !important;
            .infoImg{
                width: 100% !important;
                margin-top: 16px !important;
                margin-bottom: 34px !important;
            }
        }
    }
    .advantage{
        .contentBox{
            width: 100% !important;
            .title{
                width: 100% !important;
                line-height:26px !important;
                font-size: 16px !important;
                margin: 26px 0px !important;
                padding: 0 20px !important;
            }
            .infoTable{
                flex-direction: column;
                margin-top: 24px !important;
                margin-bottom:40px !important;
                .advantageTableImg{
                    width: 100% !important;
                    margin-bottom:30px !important;
                    padding: 0px 10px !important;
                }
                .tableWarp{
                    // width:100% !important;
                    width:calc(100% - 40px) !important;
                    border-radius: 16px 16px 16px 16px !important;
                    padding: 0px 10px !important;
                    .item{
                        height: 50px !important;
                        border-bottom: 1px dashed #C3C3C7 !important;
                        &:last-child{
                            border-bottom:0px !important;
                        }
                        .icon{
                            width: 40px !important;
                            height:40px !important;
                            margin-right:14px !important;
                        }
                        p{ 
                            font-size: 15px !important;
                        }
                    }
                }
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(150px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes fadeOn {
    from {
        opacity: 0;
        transform: translateX(-150px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
</style>